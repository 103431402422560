var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-app-dns-query"},[_c('div',{staticClass:"container-user-area"},[_c('a-card',{staticStyle:{"width":"500px"},attrs:{"hoverable":""}},[_c('div',[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"}),_vm._v(": "),(_vm.User.ID !== '')?_c('a-tag',[_vm._v(_vm._s(_vm.User.ID))]):_vm._e(),(_vm.User.ID !== '')?_c('a-icon',{attrs:{"type":"copy"},on:{"click":function () {
              _vm.copy(_vm.User.ID);
            }}}):_vm._e()],1),_c('div',[_c('a-icon',{attrs:{"slot":"prefix","type":"key"},slot:"prefix"}),_vm._v(": "),(_vm.User.Token !== '')?_c('a-tag',[_vm._v(_vm._s(_vm.User.Token))]):_vm._e(),(_vm.User.Token !== '')?_c('a-icon',{attrs:{"type":"copy"},on:{"click":function () {
              _vm.copy(_vm.User.Token);
            }}}):_vm._e()],1),_c('template',{staticClass:"ant-card-actions",slot:"actions"},[_c('a-popconfirm',{attrs:{"title":"Delete the current domain name and get a new one?","ok-text":"Yes","cancel-text":"No"},on:{"confirm":_vm.handleCreateUser}},[_c('a-tooltip',{attrs:{"title":"reset domain name"}},[_c('a-icon',{attrs:{"size":"small","type":"redo"}})],1)],1),_c('a-tooltip',{attrs:{"title":"set dns rebinding"}},[_c('a-icon',{attrs:{"size":"small","type":"setting"},on:{"click":function () {
                _vm.Shows.DnsRebindSetting = true;
                _vm.User.OldIPs = _vm.User.IPs;
              }}})],1),_c('a-popconfirm',{attrs:{"title":"Wipe all records data?","ok-text":"Yes","cancel-text":"No"},on:{"confirm":_vm.handleWipeData}},[_c('a-tooltip',{attrs:{"title":"wipe data"}},[_c('a-icon',{attrs:{"size":"small","type":"close"}})],1)],1)],1)],2),_c('div',{staticClass:"container-options-input"},[_c('a-select',{staticStyle:{"width":"120px","margin-right":"4px"},model:{value:(_vm.CurrentQueryMode),callback:function ($$v) {_vm.CurrentQueryMode=$$v},expression:"CurrentQueryMode"}},_vm._l((_vm.QueryModes),function(mode,index){return _c('a-select-option',{key:index,attrs:{"value":mode}},[_vm._v(" "+_vm._s(mode)+" ")])}),1),_c('a-input',{staticStyle:{"margin-right":"4px"},attrs:{"placeholder":"filter characters"},model:{value:(_vm.Inputs.Filter),callback:function ($$v) {_vm.$set(_vm.Inputs, "Filter", $$v)},expression:"Inputs.Filter"}}),_c('a-button',{staticStyle:{"margin-right":"4px"},attrs:{"type":"primary"},on:{"click":_vm.getLogRecords}},[_vm._v(" Refresh Record ")])],1)],1),_c('div',[_c('a-table',{attrs:{"columns":_vm.CurrentQueryMode === 'dns' ? _vm.DNSResult.fields : _vm.HttpResult.fields,"data-source":_vm.CurrentQueryMode === 'dns' ? _vm.DNSResult.data : _vm.HttpResult.data},scopedSlots:_vm._u([{key:"url",fn:function(text){return _c('a',{},[_vm._v(_vm._s(text))])}},{key:"httpraw",fn:function(rawText){return _c('span',{},[_c('a-icon',{attrs:{"type":"copy"},on:{"click":function () {
              _vm.copy(rawText);
            }}}),_c('pre',[_vm._v(_vm._s(_vm.formatHttpRaw(rawText)))])],1)}}])})],1),_c('a-modal',{attrs:{"title":"Set DNS Rebinding","visible":_vm.Shows.DnsRebindSetting},on:{"ok":_vm.handleUpdateUserDnsRebindingHosts,"cancel":function () {
        _vm.Shows.DnsRebindSetting = false;
      }}},[(_vm.User.ID)?_c('div',[_vm._v(" Host: "),_c('a-tag',[_vm._v("r."+_vm._s(_vm.User.ID))])],1):_vm._e(),_c('br'),_c('div',[_vm._l((_vm.User.IPs),function(dns){return [_c('a-tag',{key:dns,attrs:{"closable":true},on:{"close":function () {
              _vm.handleCloseDns(dns);
            }}},[_vm._v(" "+_vm._s(dns)+" ")])]}),(_vm.Shows.AddDns)?_c('a-input',{ref:"input",style:({ width: '78px' }),attrs:{"type":"text","size":"small","value":_vm.Inputs.DnsRebinding},on:{"change":_vm.handleTagInputChange,"blur":_vm.handleTagInputConfirm,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleTagInputConfirm.apply(null, arguments)}}}):_c('a-tag',{staticStyle:{"background":"#fff","borderstyle":"dashed"},on:{"click":_vm.handleShowAddDns}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" New DNS ")],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }